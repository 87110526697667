import request from '@/server/'

// 获取生物标记物列表
export const getBiomarkers = params => {
  return request({
    url: '/mind_lib/biomarker_list/',
    params
  })
}
// 获取文献列表
export const getZhikuList = params => {
  return request({
    url: '/mind_lib/articles/',
    params,
    isLoading: false
  })
}
// 获取文献详情
export const getZhikuDetail = id => {
  return request({
    url: `mind_lib/article/${id}/`
  })
}
// 获取画像图表数据
export const getChart = params => {
  return request({
    url: '/mind_lib/tag_graph/',
    params
  })
}
// 获取智库大洲统计
export const getZhiKuDashboardContinent = (params) => {
  return request({
    url: '/mind_lib/dashboard/continent/',
    method: 'get',
    params,
    isLoading: false
  })
}
// 获取智库国家统计
export const getZhiKuDashboardCountry = (params) => {
  return request({
    url: '/mind_lib/dashboard/country/',
    method: 'get',
    params,
    isLoading: false
  })
}
// 获取智库省份统计
export const getZhiKuDashboardProvince = (params) => {
  return request({
    url: '/mind_lib/dashboard/province/',
    method: 'get',
    params,
    isLoading: false
  })
}
// 获取智库发表年份数据
export const getYear = params => {
  return request({
    url: '/mind_lib/dashboard/publish_year/',
    params,
    isLoading: false
  })
}
// 获取智库生物标志物
export const getZhiKuDashboardBiomarker = params => {
  return request({
    url: '/mind_lib/dashboard/biomarker/',
    params,
    isLoading: false
  })
}
// 获取智库通讯作者排名
export const getZhiKuDashboardAuthor = params => {
  return request({
    url: '/mind_lib/dashboard/author/',
    params,
    isLoading: false
  })
}
// 获取智库机构排行
export const getZhiKuDashboardInstitution = params => {
  return request({
    url: '/mind_lib/dashboard/institution/',
    params,
    isLoading: false
  })
}
// 获取智库仪器厂家
export const getZhiKuDashboardInstrument = params => {
  return request({
    url: '/mind_lib/dashboard/instrument/',
    params,
    isLoading: false
  })
}
// 获取智库试剂品牌
export const getZhiKuDashboardReagent = params => {
  return request({
    url: '/mind_lib/dashboard/reagent/',
    params,
    isLoading: false
  })
}
// 获取智库治疗
export const getZhiKuDashboardTreatments = params => {
  return request({
    url: '/mind_lib/dashboard/treatments/',
    params,
    isLoading: false
  })
}
// 获取智库相关疾病
export const getZhiKuDashboardDiseases = params => {
  return request({
    url: '/mind_lib/dashboard/diseases/',
    params,
    isLoading: false
  })
}
// 获取智库医学方向
export const getZhiKuDashboardMedicals = params => {
  return request({
    url: '/mind_lib/dashboard/medicals/',
    params,
    isLoading: false
  })
}
// 获取智库研究人群
export const getZhiKuDashboardPopulations = params => {
  return request({
    url: '/mind_lib/dashboard/populations/',
    params,
    isLoading: false
  })
}
// 获取相关疾病列表
export const getDiseaseList = params => {
  return request({
    url: '/mind_lib/label/diseases/',
    params
  })
}

// 获取相关药物列表
export const getDrugList = params => {
  return request({
    url: '/mind_lib/label/treatments/',
    params
  })
}

// 获取医学方向列表
export const getMedicalList = params => {
  return request({
    url: '/mind_lib/label/meds/',
    params
  })
}

// 获取研究人群列表
export const getResearchList = params => {
  return request({
    url: '/mind_lib/label/populations/',
    params
  })
}

// 获取生物标记物检测列表
export const getBiomarkersList = params => {
  return request({
    url: '/mind_lib/label/bios/',
    params
  })
}

// 获取试剂品牌列表
export const getReagentList = params => {
  return request({
    url: '/mind_lib/label/reagents/',
    params
  })
}

// 获取仪器厂家列表
export const getInstrumentList = params => {
  return request({
    url: '/mind_lib/label/instruments/',
    params
  })
}
// 智库订阅
export const postSubscribe = data => {
  return request({
    url: '/mind_lib/retrievals/',
    method: 'post',
    data
  })
}
// 智库订阅列表
export const getSubscribeList = params => {
  return request({
    url: '/mind_lib/retrievals/',
    params
  })
}
// 获取订阅详情
export const getSubscribeDetail = id => {
  return request({
    url: `/mind_lib/retrieval/${id}/`
  })
}
// 删除订阅数据
export const deleteSubscribe = id => {
  return request({
    url: `/mind_lib/retrieval/${id}/`,
    method: 'delete'
  })
}
// 获取文献类型列表
export const getLiteratureTypeList = params => {
  return request({
    url: '/mind_lib/articles/article_type/',
    params
  })
}
export const getPublishYearList = params => {
  return request({
    url: '/mind_lib/articles/publish_year/',
    params
  })
}
export const getImpactFactorList = params => {
  return request({
    url: '/mind_lib/articles/impact_factor/',
    params
  })
}
