<template>
  <div>
    <svg v-if="type === 'desc'" t="1621326734410" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9469" width="12" height="12"><path d="M512 0l341.333333 426.666667H170.666667z" fill="#CCCCCC" p-id="9470"></path><path d="M512 1024l341.333333-426.666667H170.666667z" fill="#0C86FE" p-id="9471"></path></svg>
    <svg v-else t="1621327126426" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9602" width="12" height="12"><path d="M512 0l341.333333 426.666667H170.666667z" fill="#0C86FE" p-id="9603"></path><path d="M512 1024l341.333333-426.666667H170.666667z" fill="#CCCCCC" p-id="9604"></path></svg>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'desc'
    }
  }
}
</script>
